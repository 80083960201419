import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { USER_QUERY_KEY } from "../constants/queryKeys";
import { getUser } from "../services/auth-service";
import * as userLocalStore from "../local_store/userLocalStore";
import { decryptUser } from "../helpers/encryptor";

function useUser() {
  const { data: user } = useQuery({
    queryKey: [USER_QUERY_KEY],
    queryFn: async () => {
      try {
        const result = await getUser(user);
        console.log("useUser - API result:", result?.data?.data);
        return result?.data?.data;
      } catch (err) {
        return null;
      }
    },
    onError: (error) => {
      console.log("error when fetching user", error);
    },
    initialData: () => {
      const storedUser = userLocalStore.getUser();
      console.log("useUser - Stored user:", storedUser);
      return storedUser;
    },
    retryOnMount: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (!user) {
      userLocalStore.removeUser();
    } else {
      console.log("useUser - Saving user:", user);
      userLocalStore.saveUser(user);
    }
  }, [user]);

  const decryptedUser = decryptUser(user);
  console.log("useUser - Decrypted user:", decryptedUser);

  return {
    user: decryptedUser ?? null,
  };
}

export default useUser;
