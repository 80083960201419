import React, { useState } from "react";
import Layout from "../../commons/Layout";
import CardTitleBig from "../../commons/PageTitleBig";
import CustomInputField from "../../commons/CustomInputField";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { Formik, Form } from "formik";
import useLogin from "../../hooks/useLogin";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import CustomSnackBar from "../../commons/CustomSnackBar";
import { Link, useNavigate } from "react-router-dom";
import { encryptData, decryptData } from "../../helpers/encryptor";

function Login() {
  const navigate = useNavigate();
  const { logVendorIn } = useLogin();
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="flex items-center py-10">
        <div className="shadow-md rounded-lg  bg-white p-6 w-[32%] max-lg:w-3/5 max-md:w-4/5 mx-auto">
          <CardTitleBig title="Sign in to your account" />{" "}
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              logVendorIn({
                email: encryptData(values.email),
                password: encryptData(values.password),
              })
                .then((res) => {
                  console.log("Login Response:", res?.data);
                  const userData = res?.data?.data;
                  
                  // Decrypt the statuses
                  const onboardingCompleted = decryptData(userData?.onboardingCompleted);
                  const verificationStatus = decryptData(userData?.verificationStatus);
                  
                  console.log("User Status:", {
                    onboardingCompleted,
                    verificationStatus
                  });

                  // Check onboarding first
                  if (!onboardingCompleted) {
                    console.log("Onboarding not completed - redirecting to onboarding");
                    navigate("/complete-onboarding", { replace: true });
                    return;
                  }

                  // Then check approval status
                  if (verificationStatus?.toLowerCase() !== "approved") {
                    console.log("Account not approved - redirecting to awaiting approval");
                    navigate("/auth/awaiting-approval", { replace: true });
                    return;
                  }

                  // If both onboarding is completed and account is approved
                  console.log("Account approved and onboarded - redirecting to dashboard");
                  navigate("/dashboard/products", { replace: true });
                  
                  setIsError(false);
                  resetForm();
                })
                .catch((err) => {
                  setSnackBarMessage(err?.message);
                  setIsError(true);
                  setOpenSnackBar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.password) {
                errors.password = "Password is required";
              }
              return errors;
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <CustomInputField
                  label={"Email Address"}
                  type="email"
                  name="email"
                  placeholder="Please enter your email address"
                  disabled={isSubmitting}
                />
                <CustomInputField
                  label={"Password"}
                  type="password"
                  name="password"
                  placeholder="Please enter your password"
                  disabled={isSubmitting}
                />
                {!isSubmitting && (
                  <p className="font-[500] text-[14px] max-md:text-[12px] text-[#FF9D21] my-5 cursor-pointer">
                    <Link to="/auth/forgot-password">Forgot Password?</Link>
                  </p>
                )}
                <RoundedMdButton
                  label={isSubmitting ? "Please wait" : "Sign in"}
                  className="bg-[#FF9D21] text-white hover:bg-orange-600"
                  disabled={isSubmitting}
                  type="submit"
                  left={false}
                >
                  {isSubmitting && (
                    <CustomThreeDotsSpinner
                      height="20"
                      width="20"
                      color="white"
                    />
                  )}
                </RoundedMdButton>

                {!isSubmitting && (
                  <p className="font-[500] text-[14px] max-md:text-[12px] text-center mt-5">
                    Don't have an account?{" "}
                    <Link
                      to="/auth/registration-initiation"
                      className="text-[#FF9D21]"
                    >
                      Create account
                    </Link>
                  </p>
                )}
              </Form>
            )}
          </Formik>

          <CustomSnackBar
            isOpen={openSnackBar}
            onClose={() => setOpenSnackBar(false)}
            message={snackBarMessage}
            isError={isError}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Login;
