import React, { useReducer, useState, useEffect } from "react";
import Layout from "../../../commons/Layout";
import RadioItem from "../../../commons/RadioItem";
import {
  FURNITURE,
  LIGHTING,
  MAX_FULL_DESCRIPTION,
  MAX_SHORT_DESCRIPTION,
  MIN_FULL_DESCRIPTION,
  MIN_SHORT_DESCRIPTION,
} from "../../../constants/texts";
import { radioGroupHandler } from "../../../reducers/radioGroupHandler";
import { toggleRadioSelection } from "../../../reducers/radioGroupHandler/actions";
import HeadingAndDetails from "../../vendor_onboarding/HeadingAndDetails";
import CardTitle from "./../../../commons/CardTitle";
import NavButton from "./../NavButton";
import { DECOR_AND_MORE } from "./../../../constants/texts";
import { Formik, Form } from "formik";
import CustomInputField from "../../../commons/CustomInputField";
import CustomTextField from "./../../../commons/CustomTextField";
import CheckboxItem from "../../../commons/CheckboxItem";
import RoundedMdButton from "../../../commons/RoundedMdButton";
import { listCreationReducer } from "../../../reducers/listCreationReducer";
import { addItemToList } from "../../../reducers/listCreationReducer/actions";
import ProductImageUploaderUI from "./ProductImageUploaderUI";
import { useNavigate, useParams } from "react-router-dom";
import useProductCategories from "../../../hooks/useProductCategories";
import useColors from "./../../../hooks/useColors";
import CustomSelectField from "../../../commons/CustomSelectField";
import {
  fetchSubcategoriesFromProductCategoryById,
  fetchTypesFromProductSubcategoryById,
} from "../../../helpers/service-product-helpers";
import useAddVendorProduct from "../../../hooks/useAddVendorProduct";
import useUser from "../../../hooks/useUser";
import CustomSnackBar from "../../../commons/CustomSnackBar";
import useProduct from "./../../../hooks/useProduct";
import useCustomInfoDisplay from "./../../../hooks/useCustomInfoDisplay";
import CustomBackdrop from "../../../commons/CustomBackdrop";

function AddProduct({ editing }) {
  const navigate = useNavigate();

  const { messageState, setInfoDetails, InfoDisplayComponent } =
    useCustomInfoDisplay();

  const { user } = useUser();
  const params = useParams();
  const { slug } = params;
  const { product, isLoading } = useProduct(user, slug);

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);

  // State variables
  const [subcategories, setSubcategories] = useState([]);

  const { productCategories } = useProductCategories();
  const { addVendorProduct } = useAddVendorProduct();

  const { colors } = useColors();

  const initialState = {
    radioValue: "",
    list: [],
  };

  const checkboxInitialState = {
    list: [],
  };

  const [state, dispatch] = useReducer(radioGroupHandler, initialState);
  const [checkboxState, checkboxDispatch] = useReducer(
    listCreationReducer,
    checkboxInitialState
  );

  useEffect(() => {
    if (!selectedProduct) {
      setSelectedProduct(product);
    }
  }, [product]);

  //Effect to update all reducer values if product changes or exists
  useEffect(() => {
    handleRadioChange(selectedProduct?.Type?.Subcategory?.Category?.id ?? "");
  }, [selectedProduct?.Type?.Subcategory?.Category?.id]);

  useEffect(() => {
    const needsInstallation = selectedProduct?.needsInstallation;
    if (needsInstallation) {
      addToCheckedItems("installation");
    }
  }, [selectedProduct?.needsInstallation]);

  useEffect(() => {
    const colors = selectedProduct?.colors;
    if (colors) {
      const colorsArray = colors.split(",");
      for (const c of colorsArray) {
        addToColorItems(c);
      }
    }
  }, [selectedProduct?.colors]);

  useEffect(() => {
    const subs = fetchSubcategoriesFromProductCategoryById(
      productCategories,
      state.radioValue
    );
    setSubcategories(subs);
  }, [state.radioValue]);

  const [listState, listDispatch] = useReducer(
    listCreationReducer,
    initialState
  );

  const handleRadioChange = (value) => {
    dispatch(toggleRadioSelection(value));
  };

  const addToCheckedItems = (value) => {
    listDispatch(addItemToList(value));
  };

  const addToColorItems = (value) => {
    checkboxDispatch(addItemToList(value));
  };

  const resetOtherInputs = () => {};

  return (
    <Layout>
      {isLoading && <CustomBackdrop open={isLoading} text={"Please wait..."} />}
      <div className="w-[75%] max-lg:w-[85%] max-md:w-[95%] mx-auto mt-6 text-left">
        {((editing && selectedProduct) || !editing) && (
          <Formik
            // enableReinitialize={selectedProduct != null}
            initialValues={{
              id: selectedProduct?.id,
              containsFile: true,
              productCategory: state.radioValue,
              name: selectedProduct?.name ?? "",
              typeId: selectedProduct?.TypeId,
              productSubcategory: selectedProduct?.Type?.Subcategory?.id,
              shortDescription: selectedProduct?.shortDescription ?? "",
              fullDescription: selectedProduct?.fullDescription ?? "",
              width: selectedProduct?.width ?? 0,
              height: selectedProduct?.height ?? 0,
              weight: selectedProduct?.weight ?? 0,
              length: selectedProduct?.length ?? 0,
              installationPrice: selectedProduct?.installationPrice ?? 0,
              quantity: selectedProduct?.quantity ?? 0,
              imageOne: selectedProduct?.imageOne ?? "",
              imageTwo: selectedProduct?.imageTwo ?? "",
              imageThree: selectedProduct?.imageThree ?? "",
              imageFour: selectedProduct?.imageFour ?? "",
              imageFive: selectedProduct?.imageFive ?? "",
              imageSix: selectedProduct?.imageSix ?? "",
              price: selectedProduct?.price ?? 0,
              discountPrice: selectedProduct?.discountPrice ?? 0,
              needsInstallation: false,
              colors: checkboxState.list,
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              addVendorProduct({
                ...values,
                needsInstallation: listState.list.length > 0,
                accessToken: user?.accessToken,
                colors: checkboxState.list,
                vendorId: user?.id,
              })
                .then((res) => {
                  setIsError(false);
                  setOpenSnackBar(true);
                  setSnackBarMessage(res.data.message);
                  setTimeout(() => {
                    setSubmitting(false);
                    navigate("/dashboard/products", {
                      state: { message: res.data.message, isError: false },
                    });
                  }, 2000);
                  resetForm();
                  resetOtherInputs();
                })
                .catch((err) => {
                  setOpenSnackBar(true);
                  setSnackBarMessage(err?.message);
                  setIsError(true);
                  setSubmitting(false);
                })
                .finally(() => {});
            }}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = "Product name is required";
              }
              // if (!values.productCategory) {
              //   errors.productCategory = "Product category is required";
              // }
              if (!values.typeId) {
                errors.typeId = "Product type is required";
              }
              if (state?.radioValue !== 5 && !values.quantity) {
                errors.quantity = "Product quantity is required";
              }
              if (state?.radioValue !== 5 && !values.price) {
                errors.price = "Product price is required";
              }
              if (state?.radioValue !== 5 && !values.width) {
                errors.width = "Width is required";
              }
              if (state?.radioValue !== 5 && !values.length) {
                errors.length = "Length is required";
              }
              if (state?.radioValue !== 5 && !values.height) {
                errors.height = "Height is required";
              }
              if (state?.radioValue !== 5 && !values.weight) {
                errors.weight = "Weight is required";
              }
              if (!values.shortDescription) {
                errors.shortDescription = "Short description is required";
              }
              if (values.shortDescription.length < MIN_SHORT_DESCRIPTION) {
                errors.shortDescription = `Short description should be at least ${MIN_SHORT_DESCRIPTION} characters`;
              }
              if (values.shortDescription.length > MAX_SHORT_DESCRIPTION) {
                errors.shortDescription = `Short description should not be more than ${MAX_SHORT_DESCRIPTION} characters`;
              }
              // if (!values.fullDescription) {
              //   errors.fullDescription = "Full description is required";
              // }
              // if (values.fullDescription.length < MIN_FULL_DESCRIPTION) {
              //   errors.fullDescription = `Full description should be at least ${MIN_FULL_DESCRIPTION} characters`;
              // }
              // if (values.fullDescription.length > MAX_FULL_DESCRIPTION) {
              //   errors.fullDescription = `Full description should not be more than ${MAX_FULL_DESCRIPTION} characters`;
              // }
              if (!values.imageOne) {
                errors.imageOne = "Image 1 is required";
              }

              if (listState.list.includes("installation")) {
                if (!values.installationPrice) {
                  errors.installationPrice = "Installation price is required.";
                }
              }

              console.log("errors", errors);

              return errors;
            }}
          >
            {({
              isSubmitting,
              setFieldValue,
              setFieldError,
              values,
              setErrors,
              errors,
            }) => (
              <Form>
                <div className="flex justify-between">
                  <div className="text-[#318EC9] text-left">
                    <CardTitle
                      title={slug ? "Edit Product" : "Add New Product"}
                    />
                  </div>
                  <div>
                    <NavButton
                      label={"Cancel"}
                      onClick={() => navigate("/dashboard/products")}
                      className="bg-orange-500 text-white"
                    ></NavButton>
                  </div>
                </div>

                {/* Product Section */}
                <HeadingAndDetails
                  title={"Product Section"}
                  description={
                    "Select which section you want to feature your product"
                  }
                />
                <div className="mt-5 grid grid-cols-4 max-md:grid-cols-3 gap-4">
                  {productCategories?.map((category) => {
                    return (
                      <RadioItem
                        value={category?.id}
                        selectedValue={state.radioValue}
                        onClick={() => handleRadioChange(category?.id)}
                        label={category?.name}
                      />
                    );
                  })}
                </div>

                {/* Product Details */}
                <div className="mt-6">
                  {values.productCategory}
                  <HeadingAndDetails
                    title={"Product Details"}
                    description={"Fill in your product details below"}
                  />
                  <div className="mt-2"></div>

                  <div className="flex items-start max-sm:flex-col max-sm:gap-0 gap-4">
                    <div className="w-full">
                      <CustomInputField
                        name={"name"}
                        required={true}
                        placeholder="Enter product name"
                        label={"Product Name"}
                      />
                    </div>
                    <div className="w-full">
                      <CustomSelectField
                        name={"productSubcategory"}
                        required={true}
                        placeholder="Select product subcategory"
                        label={"Product Subcategory"}
                        list={subcategories}
                        selectedValue={selectedProduct?.Type?.Subcategory?.id}
                        valueKey={"id"}
                        keyValue={"name"}
                        onChange={(value) =>
                          setFieldValue("productSubcategory", value)
                        }
                      />
                    </div>
                    <div className="w-full">
                      <CustomSelectField
                        name={"typeId"}
                        required={true}
                        placeholder="Select product type"
                        label={"Product Type"}
                        valueKey={"id"}
                        keyValue={"name"}
                        selectedValue={selectedProduct?.Type?.id}
                        list={
                          fetchTypesFromProductSubcategoryById(
                            subcategories,
                            values.productSubcategory
                          ) ?? []
                        }
                        onChange={(value) => setFieldValue("typeId", value)}
                      />
                    </div>
                  </div>
                  <div className="flex items-start max-sm:flex-col max-sm:gap-0 gap-4">
                    <div className="w-full">
                      <CustomInputField
                        name={"price"}
                        required={state?.radioValue !== 5}
                        type="number"
                        placeholder="Enter product price"
                        label={"Product Price"}
                      />
                    </div>
                    <div className="w-full">
                      <CustomInputField
                        name={"discountPrice"}
                        required={false}
                        type="number"
                        placeholder="Enter product discount price (Ex. 10 for 10%)"
                        label={"Product Discount Price (in %)"}
                      />
                    </div>
                    <div className="w-full">
                      <CustomInputField
                        name={"quantity"}
                        required={state?.radioValue !== 5}
                        type="number"
                        placeholder="Enter quantity available"
                        label={"Quantity Available"}
                      />
                    </div>
                  </div>
                  <div className="flex items-start gap-4 max-md:gap-2">
                    <div className="w-full">
                      <CustomInputField
                        name={"weight"}
                        required={state?.radioValue !== 5}
                        placeholder="Enter weight in KG"
                        label={"Weight (KG)"}
                        type="number"
                      />
                    </div>
                    <div className="w-full">
                      <CustomInputField
                        name={"length"}
                        required={state?.radioValue !== 5}
                        placeholder="Enter length in CM"
                        label={"Length (CM)"}
                        type="number"
                      />
                    </div>
                    <div className="w-full">
                      <CustomInputField
                        name={"height"}
                        required={state?.radioValue !== 5}
                        placeholder="Enter height in CM"
                        label={"Height (CM)"}
                        type="number"
                      />
                    </div>
                    <div className="w-full">
                      <CustomInputField
                        name={"width"}
                        required={state?.radioValue !== 5}
                        placeholder="Enter width in CM"
                        label={"Width (CM)"}
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-full">
                      <CustomTextField
                        name={"shortDescription"}
                        extraText={`Max. ${MAX_SHORT_DESCRIPTION} characters`}
                        required={true}
                        placeholder="Enter short description"
                        label={"Short Description"}
                        showProgress={true}
                        value={values.shortDescription}
                        max={MAX_SHORT_DESCRIPTION}
                        min={MIN_SHORT_DESCRIPTION}
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-full">
                      <CustomTextField
                        name={"fullDescription"}
                        extraText={`Max. ${MAX_FULL_DESCRIPTION} characters`}
                        required={false}
                        placeholder="Enter full description"
                        label={"Full Description"}
                        value={values.fullDescription}
                        max={MAX_FULL_DESCRIPTION}
                        min={MIN_FULL_DESCRIPTION}
                        showProgress={true}
                      />
                    </div>
                  </div>
                </div>

                {/* Product Images */}
                <div className="mt-1">
                  <HeadingAndDetails
                    title={"Product Images"}
                    description={
                      "Upload different image angles of your product"
                    }
                  />

                  <div className="flex gap-4 max-sm:flex-col max-sm:gap-2 mb-1">
                    <ProductImageUploaderUI
                      label={"Image 1"}
                      required
                      name={"imageOne"}
                      setFieldError={setFieldError}
                      onChange={(value) => setFieldValue("imageOne", value)}
                      setErrors={setErrors}
                      errors={errors}
                      file={values.imageOne}
                    />
                    <ProductImageUploaderUI
                      label={"Image 2"}
                      name={"imageTwo"}
                      setFieldError={setFieldError}
                      onChange={(value) => setFieldValue("imageTwo", value)}
                      setErrors={setErrors}
                      errors={errors}
                      file={values.imageTwo}
                    />
                    <ProductImageUploaderUI
                      label={"Image 3"}
                      name={"imageThree"}
                      setFieldError={setFieldError}
                      setErrors={setErrors}
                      errors={errors}
                      onChange={(value) => setFieldValue("imageThree", value)}
                      file={values.imageThree}
                    />
                  </div>
                  <div className="flex max-sm:flex-col max-sm:gap-2 gap-4">
                    <ProductImageUploaderUI
                      label={"Image 4"}
                      name={"imageFour"}
                      setFieldError={setFieldError}
                      setErrors={setErrors}
                      errors={errors}
                      onChange={(value) => setFieldValue("imageFour", value)}
                      file={values.imageFour}
                    />
                    <ProductImageUploaderUI
                      label={"Image 5"}
                      name={"imageFive"}
                      setFieldError={setFieldError}
                      setErrors={setErrors}
                      errors={errors}
                      onChange={(value) => setFieldValue("imageFive", value)}
                      file={values.imageFive}
                    />
                    <ProductImageUploaderUI
                      label={"Image 6"}
                      name={"imageSix"}
                      setFieldError={setFieldError}
                      setErrors={setErrors}
                      errors={errors}
                      onChange={(value) => setFieldValue("imageSix", value)}
                      file={values.imageSix}
                    />
                  </div>
                </div>

                {/* Product Colors */}
                <div className="mt-6">
                  <HeadingAndDetails
                    title={"Colors Available"}
                    description={
                      "Select the colors your product is available in"
                    }
                  />
                  <div className="grid grid-cols-5 justify-start mt-2 gap-2 cursor-pointer">
                    {colors?.map((color, index) => {
                      return (
                        <>
                          <div className="flex gap-2 items-center">
                            <input
                              type="checkbox"
                              className="w-[18px] h-[18px]"
                              id={index}
                              value={color?.name}
                              checked={checkboxState.list.includes(color?.name)}
                              onChange={() => addToColorItems(color?.name)}
                            />
                            <label
                              for={index}
                              className="font-[500] text-[14px]"
                            >
                              {color?.name}
                            </label>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>

                {/* Installation */}
                <div className="my-6">
                  <HeadingAndDetails
                    title={"Installation"}
                    description={
                      "Specify if this product requires professional installation."
                    }
                  />

                  <div className="flex gap-4 mt-2 items-center">
                    <div className="w-full mt-1.5">
                      <CheckboxItem
                        label={"The product requires professional installation"}
                        selected={listState.list.includes("installation")}
                        value={"installation"}
                        groupName={""}
                        onClick={() => addToCheckedItems("installation")}
                        padding="p-[0.9em]"
                        boxLeft={true}
                      />
                    </div>
                    <div className="w-full">
                      {listState.list.includes("installation") && (
                        <CustomInputField
                          label="Installation Price"
                          type="number"
                          name="installationPrice"
                          placeholder="Enter installation price"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex justify-end mb-8">
                  <div className="w-[20%]">
                    <RoundedMdButton
                      label={isSubmitting ? "Please wait..." : "Submit"}
                      disabled={isSubmitting}
                      className="bg-[#FF9D21] font-[500] text-[16px] text-white"
                      type="submit"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
      {InfoDisplayComponent}
    </Layout>
  );
}

export default AddProduct;
