import React, { useEffect, useRef, useState } from "react";

import { Formik } from "formik";
import { Form } from "formik";
import HeadingAndDetails from "../HeadingAndDetails";
import CustomInputField from "../../../commons/CustomInputField";
import { validEmail } from "../../../helpers/validator";
import {
  PNG_FILE_TYPE,
  JPG_FILE_TYPE,
  JPEG_FILE_TYPE,
  WEBP_FILE_TYPE,
  DOCX_FILE_TYPE,
  PDF_FILE_TYPE,
} from "../../../constants/texts";
import ConfirmationModal from "../../../commons/modals/ConfirmationModal";
import { getFileTypesFriendly } from "../../../helpers/fileUpload";
import RoundedMdButton from "../../../commons/RoundedMdButton";
import useStates from "../../../hooks/useStates";
import CustomSelectField from "./../../../commons/CustomSelectField";
import useTowns from "./../../../hooks/useTowns";

const fileTypes = [
  PNG_FILE_TYPE,
  JPG_FILE_TYPE,
  JPEG_FILE_TYPE,
  WEBP_FILE_TYPE,
  PDF_FILE_TYPE,
  DOCX_FILE_TYPE,
];

function BusinessDetails({
  businessDetails,
  setBusinessDetails,
  formData = {},
  setFormData = () => {},
  user = {},
}) {
  const { states: stateList } = useStates();
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const { towns } = useTowns(state);
  const [fileInput, setFileInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const fileRef = useRef();

  return (
    <div className="mt-6">
      <HeadingAndDetails
        title={"Business Details"}
        description={"Kindly complete the form below"}
      />
      <div className="mt-2"></div>
      <Formik
        enableReinitialize
        initialValues={{
          businessEmail: businessDetails?.businessEmail ?? "",
          businessName: businessDetails?.businessName ?? "",
          registeredAddress: businessDetails?.registeredAddress ?? "",
          state: businessDetails?.state ?? "",
          region: businessDetails?.region ?? "",
          yearsOfOperation: businessDetails?.yearsOfOperation ?? "",
          taxId: businessDetails?.taxId ?? "",
          cacCertificate:
            businessDetails?.cacCertificateDocPath ??
            formData?.businessDetails?.cacCertificate ??
            "",
        }}
        validate={(values) => {
          const errors = {};

          // if (values.cacCertificate === null || !values.cacCertificate?.size) {
          //   setFileInput("");
          //   setOpenModal(true);
          // }

          if (!values.businessName) {
            errors.businessName = "Business name is required";
          }
          if (!values.businessEmail) {
            errors.businessEmail = "Business email is required";
          } else if (!validEmail(values.businessEmail)) {
            errors.businessEmail = "Invalid email address";
          }

          if (!values.registeredAddress) {
            errors.registeredAddress = "Registered address is required";
          }

          if (!values.yearsOfOperation) {
            errors.yearsOfOperation = "Years of operation is required";
          }

          if (!values.state || values.state == "") {
            setState("");
            errors.state = "State is required";
          } else {
            setState(values.state);
          }
          if (!values.region) {
            errors.region = "Region is required";
          } else {
            setRegion(values.region);
          }

          // if (!values.taxId) {
          //   errors.taxId = "Tax Id is required";
          // }

          if (!values.cacCertificate || !values.cacCertificate?.size) {
            errors.cacCertificate = "CAC certificate is required";
          }

          if (Object.keys(errors).length == 0) {
          }
          // const { cacCertificate, ...rest } = values;
          // setBusinessDetails(values);

          // localStorage.setItem(
          //   "formData",
          //   JSON.stringify({
          //     ...JSON.parse(localStorage.getItem("formData")),
          //     businessDetails: rest,
          //   })
          // );

          return errors;
        }}
      >
        {({ isSubmitting, setFieldValue, setFieldError, values }) => (
          <Form>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"businessName"}
                  required={true}
                  placeholder="Enter business name"
                  label={"Business Name"}
                  onChange={(e) => {
                    setBusinessDetails((prev) => ({
                      ...prev,
                      businessName: e.target.value,
                    }));

                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        businessDetails: {
                          ...businessDetails,
                          businessName: e.target.value,
                        },
                      })
                    );
                  }}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"businessEmail"}
                  required={true}
                  placeholder="Enter business email"
                  label={"Business Email"}
                  onChange={(e) => {
                    setBusinessDetails((prev) => ({
                      ...prev,
                      businessEmail: e.target.value,
                    }));

                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        businessDetails: {
                          ...businessDetails,
                          businessEmail: e.target.value,
                        },
                      })
                    );
                  }}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"registeredAddress"}
                  required={true}
                  placeholder="Enter registered address"
                  label={"Registered Address"}
                  onChange={(e) => {
                    setBusinessDetails((prev) => ({
                      ...prev,
                      registeredAddress: e.target.value,
                    }));

                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        businessDetails: {
                          ...businessDetails,
                          registeredAddress: e.target.value,
                        },
                      })
                    );
                  }}
                />
              </div>
            </div>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomSelectField
                  name={"state"}
                  required={true}
                  placeholder="Select state"
                  type="text"
                  label={"State"}
                  valueKey={"state_code"}
                  keyValue={"name"}
                  list={stateList?.data ?? []}
                  onChange={(value) => {
                    setFieldValue("state", value);

                    setBusinessDetails((prev) => ({
                      ...prev,
                      state: value,
                    }));

                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        businessDetails: {
                          ...businessDetails,
                          state: value,
                        },
                      })
                    );
                  }}
                  setFieldError={setFieldError}
                  selectedValue={
                    businessDetails?.state ?? formData?.businessDetails?.state
                  }
                />
              </div>
              <div className="w-full">
                {!state && (
                  <CustomInputField
                    readOnly={true}
                    placeholder="Select region"
                    name="region"
                    type="text"
                    label={"Region"}
                  />
                )}
                {state && (
                  <CustomSelectField
                    name={"region"}
                    required={true}
                    placeholder="Select region"
                    type="text"
                    label={"Region"}
                    valueKey={"name"}
                    keyValue={"name"}
                    selectedValue={
                      businessDetails?.region ??
                      formData?.businessDetails?.region
                    }
                    list={!towns?.data?.message ? towns?.data : []}
                    onChange={(value) => {
                      setFieldValue("region", value);
                      setBusinessDetails((prev) => ({
                        ...prev,
                        region: value,
                      }));

                      localStorage.setItem(
                        "formData",
                        JSON.stringify({
                          ...JSON.parse(localStorage.getItem("formData")),
                          businessDetails: {
                            ...businessDetails,
                            region: value,
                          },
                        })
                      );
                    }}
                    setFieldError={setFieldError}
                    disabled={state == ""}
                  />
                )}
              </div>
              {user?.verificationStatus !== "approved" && (
                <div className="w-full">
                  <CustomInputField
                    name={"cacCertificate"}
                    required={true}
                    type="file"
                    placeholder=""
                    label={"CAC Certificate"}
                    fileTypes={fileTypes}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    inputRef={fileRef}
                    fileCallback={setFileInput}
                    selectedFile={
                      values?.cacCertificate ??
                      formData?.businessDetails?.cacCertificate
                    }
                    onChange={(e) => {
                      setBusinessDetails((prev) => ({
                        ...prev,
                        cacCertificate: e.target.files[0],
                      }));

                      localStorage.setItem(
                        "formData",
                        JSON.stringify({
                          ...JSON.parse(localStorage.getItem("formData")),
                          businessDetails: {
                            ...businessDetails,
                            cacCertificate: e.target.files[0],
                          },
                        })
                      );
                    }}
                  />
                </div>
              )}
            </div>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"yearsOfOperation"}
                  required={true}
                  placeholder="Enter years of operation"
                  // type="number"
                  label={"Years of Operation"}
                  onChange={(e) => {
                    setBusinessDetails((prev) => ({
                      ...prev,
                      yearsOfOperation: e.target.value,
                    }));

                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        businessDetails: {
                          ...businessDetails,
                          yearsOfOperation: e.target.value,
                        },
                      })
                    );
                  }}
                />
              </div>
              {user?.verificationStatus !== "approved" && (
                <div className="w-full">
                  <CustomInputField
                    name={"taxId"}
                    required={false}
                    placeholder="Enter tax identification number"
                    label={"Tax Identification Number"}
                    onChange={(e) => {
                      setBusinessDetails((prev) => ({
                        ...prev,
                        taxId: e.target.value,
                      }));

                      localStorage.setItem(
                        "formData",
                        JSON.stringify({
                          ...JSON.parse(localStorage.getItem("formData")),
                          businessDetails: {
                            ...businessDetails,
                            taxId: e.target.value,
                          },
                        })
                      );
                    }}
                  />
                </div>
              )}
              <div className="w-full"></div>
            </div>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        isOpen={openModal && fileInput != ""}
        type={"warning"}
        title={"File upload error"}
        description={""}
        subText={
          "Invalid file type. Acceptable types (" +
          getFileTypesFriendly(fileTypes) +
          ")"
        }
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="my-5">
          <RoundedMdButton
            label={"OK"}
            onClick={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </ConfirmationModal>
    </div>
  );
}

export default BusinessDetails;
