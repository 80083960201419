import React, { useEffect, useState } from "react";
import Layout from "../../../commons/Layout";
import PageBreadCrumb from "../../../commons/PageBreadCrumb";
import InfoCard from "./../InfoCard";
import NavTab from "./../NavTab";
import OrderRowItem from "./OrderRowItem";
import { APPROVED, COMPLETED, IN_PROGRESS } from "../../../constants/texts";
import { PENDING } from "./../../../constants/texts";
import AllNavBars from "../AllNavBars";
import useUser from "./../../../hooks/useUser";
import useVendorOrders from "../../../hooks/useVendorOrders";
import Pagination from "../../../commons/Pagination";
import CustomNoRecordFound from "../../../commons/CustomNoRecordFound";
import NotFoundSVG from "../../../commons/NotFoundSVG";
import CustomSnackBar from "./../../../commons/CustomSnackBar";
import useDebounce from "../../../hooks/useDebounce";
import useDatePicker from "../../../hooks/useDatePicker";
import { getSelectedTab } from "../../../helpers/extractors";
import SearchInput from "../../../commons/SearchInput";
import CustomNavTabs from "../../../commons/CustomNavTabs";

function Orders() {
  const tabItems = ["All", "Pending", "Picked", "Shipped", "Delivered"];

  const [selected, setSelected] = useState("");

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);

  const debouncedSearchTerm = useDebounce(searchTerm);

  const { DatePickerComponent, startDate, endDate, DateDisplay } =
    useDatePicker(null, "YYYY-MM-DD");

  const { user } = useUser();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const { vendorOrderItems, isLoading } = useVendorOrders(
    user,
    pageSize,
    pageNumber,
    getSelectedTab(selectedTab),
    [startDate, endDate],
    debouncedSearchTerm
  );

  useEffect(() => {
    if (selected == IN_PROGRESS) {
      setFilteredOrders(vendorOrderItems?.inProgressOrders);
    } else if (selected == COMPLETED) {
      setFilteredOrders(vendorOrderItems?.completedOrders);
    } else {
      setFilteredOrders(vendorOrderItems?.orders);
    }
  }, [vendorOrderItems, selected]);

  return (
    <Layout>
      <div className="w-[78%] max-lg:w-[85%] max-md:w-[95%] mx-auto mt-5">
        <PageBreadCrumb
          isActiveIndex={1}
          mainText="Home"
          subText={"Vendor Dashboard"}
        />

        <AllNavBars
          user={user}
          pageDescription={"View and manage your orders"}
          selectedIndex={2}
        />

        <div className={`flex flex-wrap gap-4 justify-between my-6`}>
          <div>
            <CustomNavTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabItems}
            />
          </div>
          <div className={`flex gap-4`}>
            <SearchInput
              className="bg-white shadow-sm"
              onChange={setSearchTerm}
              value={searchTerm}
              placeholder={"Search orders..."}
            />
            {DatePickerComponent}
          </div>
        </div>

        {DateDisplay}

        <div className="flex justify-between items-center gap-3 mt-4 w-full overflow-x-auto">
          <InfoCard
            text="All Orders"
            value={vendorOrderItems?.totalRecords ?? 0}
            icon={"/assets/orders_icon.png"}
          />
          <InfoCard
            text="In Progress"
            value={vendorOrderItems?.inProgressOrders?.length ?? 0}
            icon={"/assets/pending_icon.png"}
          />
          <InfoCard
            text="Completed Orders"
            value={vendorOrderItems?.completedOrders?.length ?? 0}
            icon={"/assets/approved_icon.png"}
          />
        </div>

        <div className="flex items-center gap-3 mt-6 w-full">
          <NavTab
            label={"All Orders"}
            value={vendorOrderItems?.totalRecords ?? 0}
            isSelected={selected}
            actualValue={""}
            onClick={() => setSelected("")}
          />
          <NavTab
            label={"In Progress"}
            value={vendorOrderItems?.inProgressOrders?.length ?? 0}
            isSelected={selected}
            actualValue={IN_PROGRESS}
            onClick={() => setSelected(IN_PROGRESS)}
          />
          <NavTab
            label={"Completed Orders"}
            value={vendorOrderItems?.completedOrders?.length ?? 0}
            isSelected={selected}
            actualValue={COMPLETED}
            onClick={() => setSelected(COMPLETED)}
          />
        </div>
        <div className="w-full h-[1.2px] bg-gray-100 mt-[-1px]"></div>

        <div class="relative overflow-x-auto my-8">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead class="text-xs text-gray-700 bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Order No
                </th>
                <th scope="col" class="px-6 py-3">
                  Product
                </th>
                <th scope="col" class="px-6 py-3">
                  Quantity
                </th>
                <th scope="col" class="px-6 py-3">
                  Price
                </th>
                <th scope="col" class="px-6 py-3">
                  Delivery Phase
                </th>
                <th scope="col" class="px-6 py-3">
                  Tracking Status
                </th>
                <th scope="col" class="px-6 py-3">
                  Date created
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders?.map((orderItem) => {
                return (
                  <>
                    <OrderRowItem
                      isError={isError}
                      setIsError={setIsError}
                      setOpenSnackBar={setOpenSnackBar}
                      openSnackBar={openSnackBar}
                      snackBarMessage={snackBarMessage}
                      setSnackBarMessage={setSnackBarMessage}
                      order={orderItem}
                      user={user}
                    />
                  </>
                );
              })}
            </tbody>
          </table>

          {!isLoading && filteredOrders && filteredOrders?.length === 0 && (
            <div className="flex items-center justify-center py-24 max-md:py-12">
              <CustomNoRecordFound
                showButton={false}
                buttonText={"Got it"}
                label={`No ${selected} orders found`}
              >
                <NotFoundSVG />
              </CustomNoRecordFound>
            </div>
          )}

          {!isLoading && filteredOrders && filteredOrders?.length > 0 && (
            <div className="mt-4 mb-5">
              <Pagination
                onPageChange={setPageNumber}
                currentPage={pageNumber}
                totalCount={filteredOrders?.length ?? 0}
                pageSize={pageSize}
              />
            </div>
          )}
        </div>
      </div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </Layout>
  );
}

export default Orders;
