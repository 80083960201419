import React from "react";
import Layout from "../../commons/Layout";

function AwaitingApproval() {
  return (
    <Layout bgClassName="bg-gray-100">
      <div className="flex items-center justify-center h-[80vh] max-md:h-[50vh] py-[1em]">
        <div className="p-5 rounded-lg w-[35%] max-lg:w-3/5 max-md:w-4/5 mx-auto">
          <div className="flex justify-center">
            <img src="/assets/pending-icon.png" alt="" loading="lazy" />
          </div>
          <div className="text-[28px] max-lg:text-[22px] max-md:text-[16px] font-[600] mt-4 py-2 text-center">
            Account Under Review
          </div>
          <div className="text-[#667185] text-[14px] max-md:text-[12px] font-[500]">
            <div className="my-3 w-full mx-auto text-center">
              Your account is currently under review. Once approved, you will be able to access your dashboard.
              We will notify you via email when your account has been approved.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AwaitingApproval;
