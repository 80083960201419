import React from "react";
import PageTitleBig from "../../../commons/PageTitleBig";
import useProductCategories from "../../../hooks/useProductCategories";
import ProductTypeCard from "./ProductTypeCard";

function ProductType({ addToCheckedItems, checkedItems }) {
  const { productCategories } = useProductCategories();
  return (
    <div className="text-left">
      <PageTitleBig title="Product Type" className="w-full text-left" />
      <p className="text-[17px] max-md:text-[15px] max-sm:text-[13px] text-[#828282] mt-[-1.5em] ">
        What type(s) of products will you be selling? Please select all that
        apply
      </p>
      <div className="grid grid-cols-4 max-md:grid-cols-3 gap-4 mt-[1.5em]">
        {productCategories?.map((category, id) => {
          return (
            <ProductTypeCard
              key={id}
              label={category?.name}
              value={category?.name}
              selected={checkedItems?.includes(category?.name)}
              checkList={checkedItems}
              onClick={addToCheckedItems}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ProductType;
