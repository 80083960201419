import React, { useState, useRef } from "react";
import HeadingAndDetails from "./../HeadingAndDetails";
import { Formik } from "formik";
import { Form } from "formik";
import CustomInputField from "../../../commons/CustomInputField";
import { validEmail } from "../../../helpers/validator";
import {
  PNG_FILE_TYPE,
  JPG_FILE_TYPE,
  JPEG_FILE_TYPE,
  WEBP_FILE_TYPE,
  DOCX_FILE_TYPE,
  PDF_FILE_TYPE,
} from "../../../constants/texts";
import CustomSelectField from "../../../commons/CustomSelectField";
import { MEANS_OF_ID } from "../../../constants/data";
import useCountries from "./../../../hooks/useCountries";
import ConfirmationModal from "../../../commons/modals/ConfirmationModal";
import RoundedMdButton from "../../../commons/RoundedMdButton";
import { getFileTypesFriendly } from "../../../helpers/fileUpload";

const fileTypes = [
  PNG_FILE_TYPE,
  JPG_FILE_TYPE,
  JPEG_FILE_TYPE,
  WEBP_FILE_TYPE,
  PDF_FILE_TYPE,
  DOCX_FILE_TYPE,
];

function OwnerDetails({
  ownerDetails,
  setOwnerDetails,
  formData = {},
  setFormData = () => {},
}) {
  const [fileInput, setFileInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const idFileRef = useRef();

  const { countries } = useCountries();

  return (
    <div className="mt-6">
      <HeadingAndDetails
        title={"Owner Details"}
        description={"Kindly complete the form below"}
      />
      <div className="mt-2"></div>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: ownerDetails?.firstName ?? "",
          lastName: ownerDetails?.lastName ?? "",
          middleName: ownerDetails?.middleName ?? "",
          nationality: ownerDetails?.nationality ?? "",
          meansOfIdentification: ownerDetails?.meansOfIdentification ?? "",
          meansOfIdentificationFile:
            ownerDetails?.meansOfIdentificationFile ?? "",
        }}
        validate={(values) => {
          const errors = {};

          if (!values.firstName) {
            errors.firstName = "First name is required";
          }

          if (!values.lastName) {
            errors.lastName = "Last name is required";
          }

          if (!values.nationality) {
            errors.nationality = "Nationality is required";
          }

          if (!values.meansOfIdentification) {
            errors.meansOfIdentification =
              "Means of identification is required";
          }

          // Check if file is required and not uploaded
          if (!values.meansOfIdentificationFile) {
            errors.meansOfIdentificationFile = "Means of identification file is required";
          }

          // Only check file size if a file was uploaded
          if (values.meansOfIdentificationFile && !values.meansOfIdentificationFile.size) {
            errors.meansOfIdentificationFile = "Please upload a valid identification document";
          }

          if (Object.keys(errors).length == 0) {
          }
          // setOwnerDetails(values);

          // const { meansOfIdentificationFile, ...rest } = values;
          // localStorage.setItem(
          //   "formData",
          //   JSON.stringify({
          //     ...JSON.parse(localStorage.getItem("formData")),
          //     ownerDetails: rest,
          //   })
          // );

          return errors;
        }}
      >
        {({ isSubmitting, setFieldError, setFieldValue }) => (
          <Form>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"firstName"}
                  required={true}
                  placeholder="Enter first name"
                  label={"First Name"}
                  onChange={(e) => {
                    setOwnerDetails((prev) => ({
                      ...prev,
                      firstName: e.target.value,
                    }));

                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        ownerDetails: {
                          ...ownerDetails,
                          firstName: e.target.value,
                        },
                      })
                    );
                  }}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"lastName"}
                  required={true}
                  placeholder="Enter last name"
                  label={"Last Name"}
                  onChange={(e) => {
                    setOwnerDetails((prev) => ({
                      ...prev,
                      lastName: e.target.value,
                    }));

                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        ownerDetails: {
                          ...ownerDetails,
                          lastName: e.target.value,
                        },
                      })
                    );
                  }}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"middleName"}
                  required={false}
                  placeholder="Enter middle name"
                  label={"Middle Name"}
                  onChange={(e) => {
                    setOwnerDetails((prev) => ({
                      ...prev,
                      middleName: e.target.value,
                    }));

                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        ownerDetails: {
                          ...ownerDetails,
                          middleName: e.target.value,
                        },
                      })
                    );
                  }}
                />
              </div>
            </div>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomSelectField
                  name={"nationality"}
                  list={countries?.data ?? []}
                  required={true}
                  placeholder="Select nationality"
                  label={"Nationality"}
                  onChange={(value) => {
                    setFieldValue("nationality", value);

                    setOwnerDetails((prev) => ({
                      ...prev,
                      nationality: value,
                    }));

                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        ownerDetails: {
                          ...ownerDetails,
                          nationality: value,
                        },
                      })
                    );
                  }}
                  selectedValue={
                    ownerDetails?.nationality ??
                    formData?.ownerDetails?.nationality
                  }
                />
              </div>
              <div className="w-full">
                <CustomSelectField
                  name={"meansOfIdentification"}
                  required={true}
                  placeholder="Select means of identification"
                  label={"Means of Identification"}
                  list={MEANS_OF_ID}
                  onChange={(value) => {
                    setFieldValue("meansOfIdentification", value);

                    setOwnerDetails((prev) => ({
                      ...prev,
                      meansOfIdentification: value,
                    }));

                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        ownerDetails: {
                          ...ownerDetails,
                          meansOfIdentification: value,
                        },
                      })
                    );
                  }}
                  selectedValue={
                    ownerDetails?.meansOfIdentification ??
                    formData?.ownerDetails?.meansOfIdentification
                  }
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"meansOfIdentificationFile"}
                  required={true}
                  type="file"
                  placeholder=""
                  label={"Attach Means of Identification"}
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                  onChange={(event) => {
                    const file = event.currentTarget.files[0];
                    if (file) {
                      setFieldValue("meansOfIdentificationFile", file);
                    }
                  }}
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        isOpen={openModal && fileInput != ""}
        type={"warning"}
        title={"File upload error"}
        description={""}
        subText={
          "Invalid file type. Acceptable types (" +
          getFileTypesFriendly(fileTypes) +
          ")"
        }
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="my-5">
          <RoundedMdButton
            label={"OK"}
            onClick={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </ConfirmationModal>
    </div>
  );
}

export default OwnerDetails;
