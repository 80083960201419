import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import CardTitle from "../../commons/CardTitle";
import CustomInputField from "../../commons/CustomInputField";
import FullRoundedButton from "../../commons/FullRoundedButton";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import useUser from "../../hooks/useUser";
import useUpdateVendorProfile from "./../../hooks/useUpdateVendorProfile";

function AccountProfile() {
  const { updateVendorProfile } = useUpdateVendorProfile();
  const { user } = useUser();
  const [currentUser, setCurrentUser] = useState(user);

  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();

  return (
    <div className="shadow-md rounded-lg w-[70%] max-lg:w-full bg-white p-6 text-left">
      <CardTitle title="My Profile" />

      {/* Form */}
      <Formik
        enableReinitialize
        initialValues={{
          firstName: currentUser?.firstName,
          lastName: currentUser?.lastName,
          email: currentUser?.email,
          phoneNumber: currentUser?.phoneNumber,
          id: currentUser?.id,
          accessToken: currentUser?.accessToken,
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          updateVendorProfile({ ...values, containsFile: true })
            .then((res) => {
              setInfoDetails({
                message: res?.data?.message,
                isError: false,
              });

              resetForm();
            })
            .catch((err) => {
              setInfoDetails({
                message: err?.message,
                isError: true,
              });
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Email is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.lastName) {
            errors.lastName = "Last name is required";
          }
          if (!values.firstName) {
            errors.firstName = "First name is required";
          }
          if (!values.phoneNumber) {
            errors.phoneNumber = "Mobile number is required";
          }
          return errors;
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="flex items-center gap-4 max-md:flex-col max-md:gap-0 justify-between">
              <div className="w-full">
                <CustomInputField
                  label={"First Name"}
                  type="text"
                  placeholder="Please enter your first name"
                  name="firstName"
                  disabled={isSubmitting}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  label={"Last Name"}
                  type="text"
                  placeholder="Please enter your last name"
                  name="lastName"
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <CustomInputField
              label={"Email Address"}
              type="email"
              placeholder="Please enter your email address"
              name="email"
              disabled={isSubmitting}
            />
            <CustomInputField
              label={"Phone Number"}
              type="text"
              placeholder="Please enter your phone number"
              name="phoneNumber"
              disabled={isSubmitting}
            />
            <div className="w-[30%] max-md:w-3/5 mt-4">
              <FullRoundedButton
                disabled={isSubmitting}
                label={isSubmitting ? "Please wait" : "Update profile"}
                className="text-[14px] text-white bg-[#FF9D21]"
                type="submit"
              >
                {isSubmitting && (
                  <CustomThreeDotsSpinner
                    height="20"
                    width="20"
                    color="white"
                  />
                )}
              </FullRoundedButton>
            </div>
          </Form>
        )}
      </Formik>

      {InfoDisplayComponent}
    </div>
  );
}

export default AccountProfile;
