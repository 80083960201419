import CustomPriceDisplay from "../../../commons/CustomPriceDisplay";

function PaymentRowItem({ order }) {
  return (
    <>
      <tr class="bg-white border-b">
        <th
          scope="row"
          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
        >
          <div className="flex items-center gap-6">
            <div>
              <p className="text-[13px] text-[#667085] font-[400] mt-1">
                #{order?.orderNumber}
              </p>
            </div>
          </div>
        </th>
        <td class="px-6 py-4 text-[14px] font-[500]">{order?.Product?.name}</td>
        <td class="px-6 py-4 text-[14px] font-[500]">{order?.quantity}</td>
        <td class="px-6 py-4 text-[14px] font-[500]">
          <CustomPriceDisplay price={order?.subTotalPrice} />
        </td>
      </tr>
    </>
  );
}

export default PaymentRowItem;
