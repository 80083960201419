import React, { useState } from "react";
import RoundedMdButton from "../../commons/RoundedMdButton";
import OnboardingNavigator from "./seller/OnboardingNavigator";
import PartnerType from "./seller/PartnerType";
import SignupContent from "./../auth/SignupContent";
import Layout from "./../../commons/Layout";
import Maker from "./maker";
import Installer from "./installer";
import { INSTALLER, MAKER, SELLER } from "../../constants/texts";

function Onboarding() {
  const [selectedPartnerTypeCard, setSelectedPartnerTypeCard] = useState(0);
  const [stepNumber, setStepNumber] = useState(0);

  const onNextClick = () => {
    if (
      selectedPartnerTypeCard === SELLER ||
      selectedPartnerTypeCard === MAKER
    ) {
      setStepNumber(1);
    } else if (selectedPartnerTypeCard === INSTALLER) {
      setStepNumber(3);
    }
  };

  return (
    <Layout>
      <div className="my-8 w-3/4 max-md:w-[90%] mx-auto">
        {stepNumber < 2 && (
          <OnboardingNavigator
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            showBackButtonIndex={0}
          />
        )}

        {/* Partner type */}
        {stepNumber === 0 && (
          <div className="mt-12 w-full">
            <PartnerType
              selected={selectedPartnerTypeCard}
              setSelected={setSelectedPartnerTypeCard}
            />

            <div className="flex justify-end mt-8">
              <div className="w-[20%]">
                <RoundedMdButton
                  label={"Next"}
                  disabled={selectedPartnerTypeCard === 0}
                  className="bg-[#FF9D21] font-[500] text-[16px] text-white"
                  onClick={onNextClick}
                />
              </div>
            </div>
          </div>
        )}

        {/* Sign up */}
        {stepNumber === 1 && (
          <SignupContent
            partnerType={selectedPartnerTypeCard}
            setStepNumber={setStepNumber}
          />
        )}

        {/* Maker onboarding */}
        {stepNumber === 2 && (
          <Maker setStepNumber={setStepNumber} stepNumber={stepNumber} />
        )}

        {/* Installer onboarding */}
        {stepNumber === 3 && (
          <Installer setStepNumber={setStepNumber} stepNumber={stepNumber} />
        )}
      </div>
    </Layout>
  );
}

export default Onboarding;
