import React, { useState } from "react";
import CustomPriceDisplay from "./../../../commons/CustomPriceDisplay";
import StyledSpan from "../../../commons/StyledSpan";
import { CiEdit } from "react-icons/ci";
import { AiTwotoneDelete } from "react-icons/ai";
import { PiEmpty } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import GeneralModal from "../../../commons/GeneralModal";
import { Form, Formik } from "formik";
import RoundedMdButton from "../../../commons/RoundedMdButton";
import CustomThreeDotsSpinner from "../../../commons/spinners/CustomThreeDotsSpinner";
import useUpdateProduct from "../../../hooks/useUpdateProduct";
import useDeleteProduct from "../../../hooks/useDeleteProduct";
import { capitalizeFirstLetter } from "../../../helpers/strings";

function ProductRowItem({
  product,
  user,
  openSnackBar,
  setOpenSnackBar,
  snackBarMessage,
  setSnackBarMessage,
  isError,
  setIsError,
}) {
  const navigate = useNavigate();

  const [showOutOfStockModal, setShowOutOfStockModal] = useState(false);
  const [deleteProductModal, setDeleteProductModal] = useState(false);

  const { updateProductDetails } = useUpdateProduct();
  const { deleteProductDetails } = useDeleteProduct();

  const isBespoke = product?.Type?.Subcategory?.Category?.slug === "bespoke";

  return (
    <tr class="bg-white border-b text-left">
      <th scope="row" class="px-2 py-4 font-medium text-gray-900">
        <div className="flex items-center gap-4">
          <div className="border w-[80px] h-[80px] max-md:w-[40px] max-md:h-[40px] bg-blue-100">
            <img
              className="w-[80px] h-[80px] max-md:w-[40px] max-md:h-[40px]"
              src={product?.imageOne}
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="w-[20em] max-md:w-[12em]">
            <p className="text-[15px] max-md:text-[13px] font-[600]">
              {product?.name}
            </p>
            <p className="text-[13px] max-md:text-[11px] text-[#667085] font-[400] mt-1 text-wrap">
              {product?.shortDescription}
            </p>
          </div>
        </div>
      </th>
      <td class="px-2 py-4 max-md:py-2 text-[13px] max-md:text-[11px]">
        {product?.Type?.Subcategory?.Category?.name}
      </td>
      <td class="px-2 py-4 max-md:py-2">
        <StyledSpan
          text={product?.Type?.Subcategory?.name}
          bgColor={"bg-[#EFF8FF]"}
          textColor="text-[#2E90FA]"
        />
      </td>
      <td class="px-2 py-4 max-md:py-2">
        <CustomPriceDisplay price={product?.price} />
      </td>
      <td class="px-2 py-4 max-md:py-2 text-[13px] max-md:text-[11px]">
        <span>{product?.quantity}</span>
      </td>
      <td class="px-2 py-4 max-md:py-2">
        <StyledSpan
          text={capitalizeFirstLetter(product?.status)}
          bgColor={"bg-[#ECFDF3]"}
          textColor="text-[#027A48]"
        />
      </td>
      <td class="px-2 py-4 max-md:py-2">
        <div className="flex gap-3">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/products/" + product?.slug);
            }}
          >
            <CiEdit className="text-blue-400 text-[20px] max-lg:text-[17px] max-md:text-[14px]" />
          </span>
          <span
            onClick={() => setDeleteProductModal(true)}
            className="cursor-pointer"
            title="Delete a product"
          >
            <AiTwotoneDelete className="text-red-400 text-[20px] max-lg:text-[17px] max-md:text-[14px]" />
          </span>
          {!isBespoke && (
            <span
              onClick={() => setShowOutOfStockModal(true)}
              className="cursor-pointer"
              title="Set product to out of stock"
            >
              <PiEmpty className="text-red-400 text-[20px] max-lg:text-[17px] max-md:text-[14px]" />
            </span>
          )}
        </div>
      </td>
      <GeneralModal
        onClose={() => setShowOutOfStockModal(false)}
        showCloseButton={true}
        isOpen={showOutOfStockModal}
      >
        <div className="flex justify-center">
          <img src="/assets/cart-alternative-icon.png" />
        </div>
        <div className="text-black">
          <p className="font-[400] text-[14px] max-md:text-[12px] my-3">
            Set <span className="font-bold">{product?.name}</span> as out of
            stock
          </p>
          {/* <p className="font-[500] text-[18px] max-md:text-[14px] mb-4">
            {order?.Product?.name}
          </p> */}
        </div>
        <div>
          <Formik
            initialValues={{
              accessToken: user?.accessToken,
              productId: product?.id,
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              updateProductDetails(values)
                .then((res) => {
                  setOpenSnackBar(true);
                  setSnackBarMessage(res?.data?.message);
                  setShowOutOfStockModal(false);
                  setIsError(false);
                  resetForm();
                })
                .catch((err) => {
                  setSnackBarMessage(err?.message);
                  setIsError(true);
                  setOpenSnackBar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <div className="flex gap-3 items-center">
                  <RoundedMdButton
                    label={isSubmitting ? "Please wait" : "Cancel"}
                    className="border hover:bg-gray-100"
                    disabled={isSubmitting}
                    type="button"
                    onClick={() => setShowOutOfStockModal(false)}
                  ></RoundedMdButton>
                  <RoundedMdButton
                    label={isSubmitting ? "Please wait" : "Yes"}
                    className="bg-[#FF9D21] text-white hover:bg-orange-600"
                    disabled={isSubmitting}
                    type="submit"
                    left={false}
                  >
                    {isSubmitting && (
                      <CustomThreeDotsSpinner
                        height="20"
                        width="20"
                        color="white"
                      />
                    )}
                  </RoundedMdButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </GeneralModal>
      <GeneralModal
        onClose={() => setDeleteProductModal(false)}
        showCloseButton={true}
        isOpen={deleteProductModal}
      >
        <div className="flex justify-center">
          <img src="/assets/cart-alternative-icon.png" />
        </div>
        <div className="text-black">
          <p className="font-[400] text-[14px] max-md:text-[12px] my-3">
            Are you sure you want to delete{" "}
            <span className="font-bold">{product?.name}</span>?
          </p>
        </div>
        <div>
          <Formik
            initialValues={{
              accessToken: user?.accessToken,
              productId: product?.id,
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              deleteProductDetails(values)
                .then((res) => {
                  setOpenSnackBar(true);
                  setSnackBarMessage(res?.data?.message);
                  setDeleteProductModal(false);
                  setIsError(false);
                  resetForm();
                })
                .catch((err) => {
                  setSnackBarMessage(err?.message);
                  setIsError(true);
                  setOpenSnackBar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <div className="flex gap-3 items-center">
                  <RoundedMdButton
                    label={isSubmitting ? "Please wait" : "Cancel"}
                    className="border hover:bg-gray-100"
                    disabled={isSubmitting}
                    type="button"
                    onClick={() => setDeleteProductModal(false)}
                  ></RoundedMdButton>
                  <RoundedMdButton
                    label={isSubmitting ? "Please wait" : "Yes"}
                    className="bg-[#FF9D21] text-white hover:bg-orange-600"
                    disabled={isSubmitting}
                    type="submit"
                    left={false}
                  >
                    {isSubmitting && (
                      <CustomThreeDotsSpinner
                        height="20"
                        width="20"
                        color="white"
                      />
                    )}
                  </RoundedMdButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </GeneralModal>
    </tr>
  );
}

export default ProductRowItem;
